<template>
  <div class="error-container">
    <van-image width="100%" :style="{height: screenHeight + 'px'}" :src="require('../../assets/img/404.jpg')" />
  </div>
</template>

<script>
export default {
  data(){
    return{
      screenHeight: 0
    }
  }, 
  created(){
    this.screenHeight = document.documentElement.clientHeight;
  }
}
</script>

<style scoped>
</style>